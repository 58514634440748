import SplitLayout from "@components/Footer/SplitLayout";
import {Btn} from "@components/V5Comp";
import {useTranslation} from "ni18n";
import React from "react";
import {useV5ImgSrc} from "src/useV5ImgSrc";

import {useTypedSelector} from "../../../store/index";
import useMediaQuery from "../../../useMediaQuery";
import {v5Pages} from "../../_common/_constants";

const Top: React.VoidFunctionComponent = () => {
  const i18n = useTranslation();
  const {isMobile} = useTypedSelector(state => state.config);
  const isSm = useMediaQuery("sm");
  const isSmOrMobile = isSm || isMobile;
  return (
    <SplitLayout className={`aife fdc-sm aifs-sm contrast-tbt ${isSmOrMobile ? "pb6" : "pb24"}`}>
      <div className="dg gafr gg8 gg4-xs fx3 mb5-sm df-sm">
        <div>
          <img height={50} width={50} src={useV5ImgSrc("/coloredLogo", true)} alt="Carbon Health" />
        </div>
        <h2 className="fs32 fs24-sm gray800 font-c">
          {i18n.t("All your healthcare,\n in one place.")}
        </h2>
      </div>
      <div className="fx2 pl20-sm pl16-xs">
        <div className="dg aife gg2 gafc">
          <Btn
            link
            href={{pathname: v5Pages.download}}
            fontSize={isSm ? 0.75 : 1}
            className="focus-bsDarkBlue3"
          >
            {i18n.t("Get the App")}
          </Btn>
          <Btn
            link
            href={{pathname: v5Pages.locations}}
            fontSize={isSm ? 0.75 : 1}
            className="focus-bsDarkBlue3"
          >
            {i18n.t("Find a Location")}
          </Btn>
        </div>
      </div>
    </SplitLayout>
  );
};

export default Top;
